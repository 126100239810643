// import * as PIXI from 'https://esm.run/pixi.js';

import * as PIXI from 'pixi.js';
import '@pixi/graphics-extras';

import {CRTFilter} from '@pixi/filter-crt';
import {AdvancedBloomFilter} from '@pixi/filter-advanced-bloom';
// import {TiltShiftFilter} from '@pixi/filter-tilt-shift';

import {gsap} from 'gsap';
import * as ease from 'gsap';
import {PixiPlugin} from 'gsap/all';

import {Emitter, upgradeConfig} from '@pixi/particle-emitter';
import particleConfig from './emitter.json';

const app = new PIXI.Application({ resizeTo: window });

PixiPlugin.registerPIXI(PIXI);
gsap.registerPlugin(PixiPlugin);

class Triangle extends PIXI.Graphics {
    lineWidth = 1;
    size = 150;
    glitch = 0.8;
    sides = 3;
    rotation = -30 * PIXI.DEG_TO_RAD;
    tail?: Emitter;
    constructor() {
        super();
        this.interactive = true;
        this.buttonMode = true;

        this.on('pointerdown', ()=>{
            if (this.tail) {
                this.tail.emit = true;
            }
            gsap.timeline().to(this, {
                lineWidth: 10,
                duration: 0.5,
                size: 40,
                glitch: 0.9,
                x: 25,
                ease: ease.Expo.easeInOut,
            }).to(starfield, {
                speed: 10,
            }, 0).to(bloomFilter, {
                blur: 0,
            }, 0)
        });
        this.on('pointerup', ()=>{
            if (this.tail) {
                this.tail.emit = false;
            }
            gsap.timeline().to(this, {
                lineWidth: 1,
                size: 150,
                x: 0,
                y: 0
            }).to(starfield, {
                speed: 1,
                duration: 2,
            }, 0).to(bloomFilter, {
                blur: 10,
            }, 0).delay(3)
        });

        Promise.all([
            PIXI.Texture.fromURL(require('./Pixel25px.png')),
            PIXI.Texture.fromURL(require('./Pixel50px.png')),
            PIXI.Texture.fromURL(require('./Pixel100px.png')),
        ]).then(
            ([particleTextures]) => {
                this.tail = new Emitter(this.parent, upgradeConfig(particleConfig, particleTextures));
                this.tail.emit = false;
            }
        )
       
    }
    update(deltaTime) {
        const mousePos = app.renderer.plugins.interaction.mouse.getLocalPosition(this);
        // this.rotation = (PIXI.PI_2 / 2) + Math.atan2(mousePos.x, mousePos.y);
        // this.rotation += deltaTime / 1000 * 10;
        // if (this.rotation > PIXI.PI_2) {
        //     this.rotation -= PIXI.PI_2;
        // }
        // this.position.copyFrom(mousePos);
        if (Math.random() > this.glitch) {
            this.clear();
            this.beginFill(0x000000, 1);
        }
        this.lineStyle(Math.random() * this.lineWidth, Math.random() * 0xffffff, 1.0);
        this.drawRegularPolygon(app.view.width/2, app.view.height/2, this.size, this.sides, this.rotation);
        this.tail?.updateSpawnPos(app.view.width/2, app.view.height/2 - 50);
        this.tail?.update(deltaTime);
    }
}

class Circle extends PIXI.Graphics {
    lineWidth: 1.0;
    update(deltaTime) {
        this.clear();
        this.lineStyle(this.lineWidth, 0xffffff, 1.0);
        this.drawCircle(app.view.width/2, app.view.height/2, Math.min(app.view.width, app.view.height) / 2);
    }
}

class StarField extends PIXI.Graphics {
    glitch = 0.9;
    stars = [];
    speed = 1;
    constructor() {
        super();
        for(let i=0; i<100; i++) {
            this.stars.push({
                x: (Math.random() - 0.5) * app.view.width,
                y: (Math.random() - 0.5) * app.view.height,
            });
        }
    }

    update(deltaTime) {
        this.clear();
        this.lineStyle(1.0, 0xffffff, 1.0);
        for(let i=0; i<this.stars.length; i++) {
            this.stars[i].x -= deltaTime * this.speed;
            if (this.stars[i].x < -app.view.width / 2) {
                this.stars[i].x += app.view.width;
            }
            this.moveTo(this.stars[i].x + app.view.width / 2 + Math.sin(this.stars[i].y) * 10, this.stars[i].y + app.view.height / 2);
            this.lineTo(this.stars[i].x + app.view.width / 2, this.stars[i].y + app.view.height / 2);
        }
    }
}

const starfield = new StarField();
app.ticker.add(starfield.update.bind(starfield));
app.stage.addChild(starfield);

const triangle = new Triangle();
app.ticker.add(triangle.update.bind(triangle));
app.stage.addChild(triangle);

const circle = new Circle();
app.ticker.add(circle.update.bind(circle));
app.stage.addChild(circle);

const crtFilter = new CRTFilter();
const bloomFilter = new AdvancedBloomFilter();

crtFilter.enabled = true;
bloomFilter.enabled = true;

bloomFilter.blur = 10;

const tween1 = gsap.fromTo(bloomFilter, {
    // blur: 1,
    brightness: 0,
    duration: 5,
},{
    // blur: 2,
    brightness: 0.5,
});

tween1.repeat(-1);
tween1.yoyo(true);

const tween2 = gsap.fromTo(circle, 5, {
    lineWidth: 1,
}, 
{
    lineWidth: 2,
});

tween2.yoyo(true);
tween2.repeat(-1);

app.stage.filters = [
    crtFilter,
    bloomFilter,
    // new AsciiFilter(),
    // new TiltShiftFilter(),
];
document.body.appendChild(app.view);
